import { createSlice } from '@reduxjs/toolkit';
import {
  getCashbackCardsData,
  getCashbackChartData,
  getCommunicationCardsData,
  getCommunicationChartsData,
  getCommunicationFilters,
  getCreditsCardsData,
  getOrdersFilters,
  getSalesCardsData,
  getSalesChartData,
} from './thunk';
import { keys } from 'lodash';

const shared = {
  isLoading: false,
  isError: false,
  error: {},
};

export const initialState = {
  filters: {
    isLoading: false,
    isError: false,
    data: [],
    error: null,
  },
  communicationsFilters: {
    isLoading: false,
    isError: false,
    data: [],
    error: null,
  },
  sales: {
    cards: {
      ...shared,
      total: [],
      impacted: [],
      incremental: [],
      cumulative: [],
    },
    chart: {
      ...shared,
      data: [],
    },
  },
  cashback: {
    cards: {
      ...shared,
      generated: [],
      redeemed: [],
      expired: [],
      available: [],
    },
    chart: {
      ...shared,
      data: [],
    },
  },
  communication: {
    cards: {
      ...shared,
      sms: {
        transactional: [],
        token: [],
      },
      email: [],
      whatsapp: [],
      general: [],
    },
    chart: {
      ...shared,
      data: [],
    },
  },
  credits: {
    cards: {
      ...shared,
      total: [],
      cashback: [],
      incentive: [],
      available: [],
    },
  },
};



const managerPanelSlice = createSlice({
  name: 'ManagerPanel',
  initialState,
  reducers: {},

  extraReducers: (builder) => {

    builder.addCase(getOrdersFilters.pending, (state) => {
      state.filters.isLoading = true;
    });
    builder.addCase(getOrdersFilters.fulfilled, (state, action) => {
      state.filters.isLoading = false;
      state.filters.data = action.payload;
    });
    builder.addCase(getOrdersFilters.rejected, (state, action) => {
      state.communicationsFilters.isError = true;
      state.communicationsFilters.error = action.payload || action.error.message;
    });

    builder.addCase(getCommunicationFilters.pending, (state) => {
      state.communicationsFilters.isLoading = true;
    });
    builder.addCase(getCommunicationFilters.fulfilled, (state, action) => {
      state.communicationsFilters.isLoading = false;
      state.communicationsFilters.data = action.payload;
    });
    builder.addCase(getCommunicationFilters.rejected, (state, action) => {
      state.communicationsFilters.isError = true;
      state.communicationsFilters.error = action.payload || action.error.message;
    });


    builder.addCase(getSalesCardsData.pending, (state, action) => {
      state.sales.cards.isLoading = true;
    });
    builder.addCase(getSalesCardsData.fulfilled, (state, action) => {
      state.sales.cards.isLoading = false;
      state.sales.cards.total = [
        { label: 'Valor total', value1: action.payload.total_billing },
        { label: 'Número total', value1: action.payload.total_orders },
        { label: 'Ticket médio', value1: action.payload.average_ticket },
      ];
      state.sales.cards.impacted = [
        {
          label: 'Valor total',
          value1: action.payload.total_billing_impacted,
          value2: action.payload.total_billing_impacted_percentage,
        },
        {
          label: 'Número total',
          value1: action.payload.total_orders_impacted,
          value2: action.payload.total_orders_impacted_percentage,
        },
        {
          label: 'Ticket médio',
          value1: action.payload.average_ticket_impacted,
          value2: action.payload.average_ticket_impacted_percentage,
        },
      ];
      state.sales.cards.incremental = [
        {
          label: 'Valor total',
          value1: action.payload.total_billing_incremental,
          value2: action.payload.total_billing_incremental_percentage,
        },
        {
          label: 'Número total',
          value1: action.payload.total_orders_incremental,
          value2: action.payload.total_orders_incremental_percentage,
        },
        {
          label: 'Ticket médio',
          value1: action.payload.average_ticket_incremental,
          value2: action.payload.average_ticket_incremental_percentage,
        },
      ];
      state.sales.cards.without_cashback = [
        {
          label: 'Valor total',
          value1: action.payload.total_billing_without_cashback,
          value2: action.payload.total_billing_without_cashback_percentage,
        },
        {
          label: 'Número total',
          value1: action.payload.total_orders_without_cashback,
          value2: action.payload.total_orders_without_cashback_percentage,
        },
        {
          label: 'Ticket médio',
          value1: action.payload.average_ticket_without_cashback,
          value2: action.payload.average_ticket_without_cashback_percentage,
        },
      ];
    });
    builder.addCase(getSalesCardsData.rejected, (state, action) => {
      state.sales.cards.isError = true;
      state.sales.cards.error = action.payload || null;
    });

    builder.addCase(getSalesChartData.pending, (state) => {
      state.sales.chart.isLoading = true;
    });
    builder.addCase(getSalesChartData.fulfilled, (state, action) => {
      state.sales.chart.isLoading = false;
      state.sales.chart.data = action.payload.map((i) => {
        const props = keys(i);
        return {
          date: i.date,
          value1: i[props[1]],
          value2: i[props[2]],
          value3: i[props[3]],
          value4: i[props[4]],
        };
      });
    });
    builder.addCase(getSalesChartData.rejected, (state, action) => {
      state.sales.chart.isError = true;
      state.sales.chart.error = action.payload || null;
    });

    builder.addCase(getCashbackCardsData.pending, (state) => {
      state.cashback.cards.isLoading = true;
    });
    builder.addCase(getCashbackCardsData.fulfilled, (state, action) => {
      state.cashback.cards.isLoading = false;
      state.cashback.cards.generated = [
        {
          label: 'Valor total',
          value1: action.payload.total_generated_cashback,
        },
      ];
      state.cashback.cards.redeemed = [
        {
          label: 'Valor total',
          value1: action.payload.total_redeemed_cashback,
          value2: action.payload.redeemed_cashback_percentage,
        },
      ];
      state.cashback.cards.available = [
        {
          label: 'Valor total',
          value1: action.payload.total_available_cashback,
          value2: action.payload.available_cashback_percentage,
        },
      ];
      state.cashback.cards.expired = [
        {
          label: 'Valor total',
          value1: action.payload.total_expired_cashback,
          value2: action.payload.expired_cashback_percentage,
        },
      ];
    });
    builder.addCase(getCashbackCardsData.rejected, (state, action) => {
      state.cashback.cards.isError = true;
      state.cashback.cards.error = action.payload || null;
    });

    builder.addCase(getCashbackChartData.pending, (state) => {
      state.cashback.chart.isLoading = true;
    });
    builder.addCase(getCashbackChartData.fulfilled, (state, action) => {
      state.cashback.chart.isLoading = false;
      state.cashback.chart.data = action.payload;
    });
    builder.addCase(getCashbackChartData.rejected, (state, action) => {
      state.cashback.chart.isError = true;
      state.cashback.chart.error = action.payload || null;
    });

    builder.addCase(getCommunicationCardsData.pending, (state) => {
      state.communication.cards.isLoading = true;
    });
    builder.addCase(getCommunicationCardsData.fulfilled, (state, action) => {
      state.communication.cards.isLoading = false;

      state.communication.cards.general = [
        {
          label: 'Total',
          value1: action.payload.total_count,
        },
        {
          label: 'Success',
          value1: action.payload.total_success_count,
        },
        {
          label: 'Error',
          value1: action.payload.total_error_count,
        },
      ];
      state.communication.cards.sms.transactional = [
        {
          label: 'Total',
          value1: action.payload.transactional_total_count,
        },
        {
          label: 'Success',
          value1: action.payload.transactional_success_count,
          value2: action.payload.transactional_success_percentage,
        },
        {
          label: 'Error',
          value1: action.payload.transactional_error_count,
          value2: action.payload.transactional_error_percentage,
        },
      ];

      state.communication.cards.sms.token = [
        {
          label: 'Total',
          value1: action.payload.token_total_count,
        },
        {
          label: 'Success',
          value1: action.payload.token_success_count,
          value2: action.payload.token_success_percentage,
        },
        {
          label: 'Error',
          value1: action.payload.token_error_count,
          value2: action.payload.token_error_percentage,
        },
      ];

      state.communication.cards.email = [
        {
          label: 'Total',
          value1: action.payload.email_total_count,
        },
        {
          label: 'Success',
          value1: action.payload.email_success_count,
          value2: action.payload.email_success_percentage,
        },
        {
          label: 'Error',
          value1: action.payload.email_error_count,
          value2: action.payload.email_error_percentage,
        },
      ];

      state.communication.cards.whatsapp = [
        {
          label: 'Total',
          value1: action.payload.whatsapp_total_count,
        },
        {
          label: 'Success',
          value1: action.payload.whatsapp_success_count,
          value2: action.payload.whatsapp_success_percentage,
        },
        {
          label: 'Error',
          value1: action.payload.whatsapp_error_count,
          value2: action.payload.whatsapp_error_percentage,
        },
      ];
    });
    builder.addCase(getCommunicationCardsData.rejected, (state, action) => {
      state.communication.cards.isError = true;
      state.communication.cards.error = action.payload || action.error.message;
    });
    builder.addCase(getCommunicationChartsData.pending, (state, action) => {
      state.communication.chart.isLoading = true;
    });
  
    builder.addCase(getCreditsCardsData.fulfilled, (state, action) => {
      state.credits.cards.isLoading = false;

      state.credits.cards.total = [
        {
          label: 'generated',
          value1: action.payload.total.generated.credit_sum,
          value2: action.payload.total.generated.credit_count,
        },
        {
          label: 'released',
          value1: action.payload.total.released.credit_sum,
          value2: action.payload.total.released.credit_count,
        },
        {
          label: 'expired',
          value1: action.payload.total.expired.credit_sum,
          value2: action.payload.total.expired.credit_count,
        },
        {
          label: 'redeemed',
          value1: action.payload.total.redeemed.credit_sum,
          value2: action.payload.total.redeemed.credit_count,
        },
        {
          label: 'available',
          value1: action.payload.total.available.credit_sum,
          value2: action.payload.total.available.credit_count,
        },
      ];
      state.credits.cards.cashback = [
        {
          label: 'generated',
          value1: action.payload.order.generated.credit_sum,
          value2: action.payload.order.generated.credit_count,
        },
        {
          label: 'released',
          value1: action.payload.order.released.credit_sum,
          value2: action.payload.order.released.credit_count,
        },
        {
          label: 'expired',
          value1: action.payload.order.expired.credit_sum,
          value2: action.payload.order.expired.credit_count,
        },
        {
          label: 'redeemed',
          value1: action.payload.order.redeemed.credit_sum,
          value2: action.payload.order.redeemed.credit_count,
        },
        {
          label: 'available',
          value1: action.payload.order.available.credit_sum,
          value2: action.payload.order.available.credit_count,
        },
      ];
      state.credits.cards.incentive = [
        {
          label: 'generated',
          value1: action.payload.incentive.generated.credit_sum,
          value2: action.payload.incentive.generated.credit_count,
        },
        {
          label: 'released',
          value1: action.payload.incentive.released.credit_sum,
          value2: action.payload.incentive.released.credit_count,
        },
        {
          label: 'expired',
          value1: action.payload.incentive.expired.credit_sum,
          value2: action.payload.incentive.expired.credit_count,
        },
        {
          label: 'redeemed',
          value1: action.payload.incentive.generated.credit_sum,
          value2: action.payload.incentive.generated.credit_count,
        },
        {
          label: 'available',
          value1: action.payload.incentive.available.credit_sum,
          value2: action.payload.incentive.available.credit_count,
        },
      ];
      state.credits.cards.available = [
        {
          label: 'available',
          value1: action.payload.total.available.credit_sum,
          value2: action.payload.total.available.credit_count,
        },
        {
          label: 'cashback',
          value1: action.payload.order.available.credit_sum,
          value2: action.payload.order.available.credit_count,
        },
        {
          label: 'incentive',
          value1: action.payload.incentive.available.credit_sum,
          value2: action.payload.incentive.available.credit_count,
        },
      ];
    });

    builder.addCase(getCreditsCardsData.pending, (state) => {
      state.credits.cards.isLoading = true;
    });
    builder.addCase(getCommunicationChartsData.fulfilled, (state, action) => {
      state.communication.chart.isLoading = false;
      state.communication.chart.data = action.payload.data;
    });
    builder.addCase(getCommunicationChartsData.rejected, (state, action) => {
      state.communication.chart.isError = true;
      state.communication.chart.error = action?.payload || null;
    });
  },
});

export default managerPanelSlice.reducer;
